<template>
	<div role="link" class="project-card">
		<div class="project-card__header">
			<span class="project-card__stage">{{ content.build_status?.title }}</span>
			<h3 class="project-card__title">{{ content.title }}</h3>
<!--			<button-->
<!--				type="button"-->
<!--				@click.stop.prevent="share"-->
<!--        @touchstart.stop.prevent="share"-->
<!--				class="btn project-card__share"-->
<!--				aria-label="'Share'"-->
<!--			>-->
<!--				<or-icon type="share" />-->
<!--			</button>-->
		</div>

		<div class="project-card__image">
			<or-image
				:src="content.logo"
				:width="280"
				:alt="content.title"
				:isLoading="isLoading"
			/>
		</div>

		<div class="project-card__content">
			<p class="project-card__price">
				{{ price }}
				<span>Apartment</span>
			</p>
			<p class="project-card__district">
				{{ region }}
			</p>
			<p class="project-card__developer">
				{{ content.developer?.title }}
				<span class="project-card__developer--title">
					{{ $t('general.developer') }}
				</span>
			</p>
			<p class="project-card__square">
				<or-icon type="square" />
				<span>
					{{ square }} <sup>{{ currentSquareSymbol }}</sup>
				</span>
			</p>
			<p v-if="content.bedrooms_range.from" class="project-card__rooms">
				<or-icon type="bed" />
				<span v-if="content.has_studios">{{ $t('general.studio') }}</span>
				<span>
					{{ bedroomsRange }}
				</span>
				{{ $t('general.rooms') }}
			</p>
      <p v-else class="project-card__rooms">
        <or-icon type="bed" />
        <span v-if="content.has_studios">{{ $t('general.studio') }}</span>
      </p>
		</div>
	</div>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import { formatCash } from '@/utils/formatMoney'
import { squareSymbols } from '@/enums/areaCollections.js'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		bedroomsRange() {
			if (this.content.bedrooms_range.from == this.content.bedrooms_range.to)
				return `${this.content.bedrooms_range.from || 1}`
			else
				return `${this.content.bedrooms_range.from || 1} - ${
					this.content.bedrooms_range.to || 1
				}`
		},
		currentSquareSymbol() {
			return squareSymbols[this.$store.state.areaUnit]
		},
		price() {
			const from = `${simbol[this.$store.state.activeMoney]}${formatCash(
				this.content.price_from[money[this.$store.state.activeMoney]]
			)}`
			let to = ``
			if (this.content?.price_to?.aed > 0)
				to = ` — ${simbol[this.$store.state.activeMoney]}${formatCash(
					this.content.price_to[money[this.$store.state.activeMoney]]
				)}`
			return `${from}${to}`
		},
		square() {
			if (
				this.content?.area_from[this.$store.state.areaUnit] ===
				this.content?.area_to[this.$store.state.areaUnit]
			)
				return this.content.area_from[this.$store.state.areaUnit]
			else if (this.content?.area_from && this.content?.area_to) {
				return `${this.content.area_from[this.$store.state.areaUnit]} - ${
					this.content.area_to[this.$store.state.areaUnit]
				}`
			} else return ''
		},
		region() {
			return this.content.region?.title
				? `${this.content.region?.title} ,  ${this.content.city?.title}`
				: ''
		}
	},
	methods: {
		share() {
			navigator.clipboard
				.writeText(navigation.currentEntry.url + `/${this.content.slug}`)
				.then(() => {
					this.$store.commit('showNotif', {
						type: 'success',
						text: this.$t('notification.successCopy')
					})
				})
		}
	}
}
</script>

<style lang="scss">
.project-card {
	position: relative;
	cursor: pointer;
	overflow: hidden;

	&__header {
		position: absolute;
		top: 0;
		width: 100%;
		padding: 15px 13px;
		z-index: 1;
		text-align: left;
	}

	&__stage {
		display: inline-block;
		padding: 5px 10px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 4px;
	}

	&__title {
		position: relative;
		margin: 0;
		margin-top: 9px;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #ffffff;
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
		z-index: -1;
	}

	&__share {
		position: absolute;
		top: 19px;
		right: 13px;
		color: white;
		font-size: 17px;
	}

	&__image {
		position: relative;

		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		}

		img {
			object-fit: cover;
			width: 100%;
			aspect-ratio: 16 / 10;
			height: 100%;
			@media (max-width: 540px) {
				width: 100%;
			}
		}
		.skeleton-basic {
			width: 100%;
			height: unset;
			aspect-ratio: 16 / 10;
		}
	}

	&__content {
		margin-top: 15px;

		p {
			margin: 0;
			margin-bottom: 5px;
		}
	}

	&__price {
		font-weight: 600;
		font-size: 14px;
		text-align: left;
		line-height: 19px;

		span {
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: #c99d56;
		}
	}

	&__district {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		text-align: left;
		color: #808080;
	}

	&__developer {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 15px !important;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #808080;

		&--title {
			display: block;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: #c99d56;
		}
	}

	&__square {
		margin-bottom: 10px !important;
		display: flex;
		align-items: center;
		gap: 10px;

		span {
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			text-align: right;
			color: #c99d56;
		}

		sup {
			color: #000000;
		}
	}

	&__rooms {
		display: flex;
		align-items: center;
		gap: 5px;

		i {
			display: inline-block;
			margin-right: 4px;
		}

		span {
			display: inline-block;
			padding: 1px 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			border: 1px solid #cfcfcf;
			border-radius: 4px;
		}
	}
}
</style>
